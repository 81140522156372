import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from 'projects/common/src/public-api';
import { APIConstant } from '../constant';

@Injectable({
  providedIn: 'root'
})
export class SettingService {

  constructor(protected baseService: BaseService) { }

  addDataTableSetup(params: any): Observable<any> {
    return this.baseService.post(`${APIConstant.datatablesetup}/columns`, params);
  }

  updateDataTableSetup(params: any): Observable<any> {
    return this.baseService.put(`${APIConstant.datatablesetup}/columns`, params);
  }

  deleteDataTableSetup(id: number): Observable<any> {
    return this.baseService.delete(`${APIConstant.datatablesetup}/columns?id=` + id);
  }

  getModules(): Observable<any[]> {
    return this.baseService.get<any[]>(`${APIConstant.modules}`);
  }

  getTableListByModule(module: string): Observable<any[]> {
    return this.baseService.get<any[]>(`${APIConstant.tablist}?module=` + module);
  }

  getDataTableSetup(params: any): Observable<any[]> {
    return this.baseService.get<any[]>(`${APIConstant.datatablesetup}?module=${params.moduleType}&tableName=${params.tableType}&size=${params.size}&page=${params.page}`);
  }

  getDataTableUnusedColumnList(params: any) {
    return this.baseService.get<any[]>(`${APIConstant.datatablesetup}/unused-column-listing?module=` + params.module + `&tableName=` + params.tableName);
  }

  getApplicationSettings() {
    return this.baseService.get<any[]>(`${APIConstant.applicationsetting}`);
  }

  updateApplicationSetting(params: any) {
    return this.baseService.put<any[]>(`${APIConstant.applicationsetting}`, params);
  }
}
