import { environment } from "../../../environments/environment";

const apiPath = environment.apiPath;

export const APIConstant = {
    basePath: apiPath,
    modules: `${apiPath}/datatable/setup/module-listing`,
    tablist: `${apiPath}/datatable/setup/table-listing`,
    datatablesetup: `${apiPath}/datatable/setup`,
    applicationsetting: `${apiPath}/settings/application-settings`
};

export const PublicAPI = [];
